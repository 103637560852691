<template>
    <div class="dispersion">
        <el-card>
            <div slot="header" class="clearfix">
                <span>交易分散度</span>
                <date-range style="float: right" v-on:change="getData"></date-range>
            </div>
            <h-chart id="chart" :options="options"></h-chart>
            <div class="blackboard">
                <p class="star-level"><span>指标星级：</span>
                    <el-rate v-model="starLevel" disabled></el-rate>
                </p>
                <p>指标释义：展示账户品种的分散程度。</p>
                <p>如何使用：？</p>
            </div>
        </el-card>
    </div>
</template>

<script>
    import HChart from '@/components/HChart.vue'
    import DateRange from '@/components/DateRange.vue'
    import {Loading} from "element-ui";
    import {analysisData} from "../../utils/api";

    export default {
        name: "Dispersion",
        data() {
            return {
                starLevel: 3,
                options: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        renderTo: 'container',
                        type: 'column',
                        options3d: {
                            enabled: true,
                            alpha: 5,
                            beta: 5,
                            depth: 30,
                            viewDistance: 90
                        },
                        backgroundColor: null,
                        plotBackgroundColor: null
                    },
                    title: false,
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.y:.2f}%</b>'
                    },
                    colors: ['#EB3E41'],
                    plotOptions: {
                        series: {
                            pointWidth: 100
                        }
                    },
                    xAxis: {
                        categories: []
                    },
                    yAxis: {
                        title: false,
                    },
                    series: [],
                }
            }
        },
        components: {
            HChart,
            DateRange
        },
        methods: {
            getData() {
                if (this.$store.state.curAccountId.length === 0) {
                    return false;
                }
                let _this = this;
                let loadingInstance = Loading.service({'target': '.h-chart'});
                let accountId = JSON.stringify(this.$store.state.curAccountId);
                let dateRange = JSON.parse(this.$store.state.dateRange);
                analysisData({
                    'account': accountId,
                    'type': 'dispersion',
                    'dateRange': dateRange.range,
                    'analysisType': this.$store.state.analysisType
                }).then(response => {
                    if (response.status === 200) {
                        let options = JSON.parse(JSON.stringify(this.options));
                        options.xAxis.categories = response.data.categories;
                        options.series = response.data.series;
                        this.options = options;
                    }
                    loadingInstance.close()
                }).catch(function () {
                    _this.$message({
                        message: '网络链接错误，请稍后再试...',
                        type: 'error'
                    });
                    loadingInstance.close()
                });
            }
        },
        mounted() {
            this.getData();
        }
    }
</script>

<style lang="scss">
    .dispersion {
        .el-card {
            .el-card__header {
                background: #1777FF;
                color: #ffffff;
            }

            .chart {
                margin-bottom: 20px;
                background-image: url("../../assets/watermark.png");
                background-repeat: no-repeat;
                background-size: 269px 150px;
                background-position: center;
            }
        }
    }
</style>
